<template>
  <div class="pageMain">
    <div class="flex">
      <!-- 组织项目树 -->

      <OriginTree class="tree" :filterText="filterText" @checked="init">
        <template slot="search">
          <SearchLeft
            @search="search"
            @reset="reset"
            info="项目信息"
            :iconShow="false"
          >
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="" class="formItem">
                <el-input
                  v-model="filterText"
                  size="small"
                  placeholder="项目"
                ></el-input>
              </el-form-item>
            </el-form>
          </SearchLeft>
        </template>
      </OriginTree>

      <!--右边列表  -->
      <div class="form">
        <SearchHead @search="search" @reset="reset">
          <el-form :inline="true" :model="searchForm">
            <el-form-item label="起重设备编号">
              <el-input
                v-model="searchForm.equipNo"
                size="small"
                placeholder="起重设备编号"
              ></el-input>
            </el-form-item>

            <el-form-item label="主机IMEI">
              <el-input
                v-model="searchForm.deviceNo"
                size="small"
                placeholder="主机IMEI"
              ></el-input>
            </el-form-item>
          </el-form>
        </SearchHead>
        <CustomTables
          :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChanges="handleCurrentChanges"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
          :total="total"
          title="设备信息"
        >
          <template slot="btns">
            <el-button
              size="small"
              @click="addFrom"
              class="btn iconfont icon-piliangbianji"
              >绑定监控</el-button
            >
            <el-button
              size="small"
              @click="addFrom2"
              class="btns iconfont icon-xianghujiaohuan-zuoyou"
              >解绑监控</el-button
            >
          </template>

          <el-table-column type="index" align="center" width="55" label="序号"  >
          </el-table-column>
          <el-table-column prop="actNo" label="现场编号" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="equipNo" label="起重设备编号" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="deviceNo" label="监控主机IMEI" align="center" show-overflow-tooltip>
          </el-table-column>
          <!-- <el-table-column prop="useState" label="启用状态" align="center">
            </el-table-column> -->
          <el-table-column
            prop="createTime"
            label="最后操作时间"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="createUser" label="操作人员" align="center">
            <!-- <template slot-scope="scope">
              <span
             v-if="scope.row.createUser == 0"
              >
            
            查看
            </span>
            <span
             v-if="scope.row.createUser == 2"
              >
            查看
            </span>
            </template> -->
          </el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <span
                @click="detailItem(scope.row)"
                class="detail"
              >
            查看
            </span>
           
            </template>
          </el-table-column>
        </CustomTables>
      </div>
    </div>
    <bindingMonitor
      :visible.sync="showDialog"
      @close="colseDialog"
      :title="title"
      @done="doneDialog"
    ></bindingMonitor>
    <unbindMonitor
      :visible.sync="showDialog2"
      @close="colseDialog2"
      :title="title2"
      :item="item"
      @done="doneDialog2"
    ></unbindMonitor>
  </div>
</template>

<script>
import OriginTree from "../../../components/common/OriginTree.vue";
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import SearchLeft from "../../../components/common/SearchLeft.vue";
import bindingMonitor from "../../../components/basic/bindingMonitor.vue";
import unbindMonitor from "../../../components/basic/unbindMonitor.vue";
import basic from "../../../api/modules/basic";
export default {
  components: {
    OriginTree,
    SearchHead,
    CustomTables,
    SearchLeft,
    bindingMonitor,
    unbindMonitor,
  },
  name: "statistics",
  data() {
    return {
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      tableData: [],

      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      total: 0,
      show: true,
      showDialog: false,
      title: "",
      showDialog2: false,
      title2: "",
      filterText: "",
      item: "",
    };
  },
  mounted() {},
  methods: {
    addFrom() {
      this.showDialog = true;
      this.title = "绑定监控";
    },
    addFrom2() {
      if (!this.item) {
        this.$message({
          message: "请先选择要解绑的设备",
          type: "warning",
        });
      } else {
        this.showDialog2 = true;
        this.title2 = "解绑监控";
      }
    },
    init(val) {
      this.searchForm.orgaId = val.orgaId;
      this.searchForm.currPage = 1;
      this.orgaId = val.orgaId;
      this.getList();
    },
    getList() {
      basic.deviceUseGetPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
        orgaId: this.orgaId,
      };
      this.getList();
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.pageNum = pageIndex;
      this.getList();
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      this.getList();
    },
    tableExport() {
      // 导出
    },
    handleSelectionChange(val) {
      console.log(val);
      // this.item = val[0]
    },
    detailItem(row) {
      //详情
      console.log('row',row);
      this.$router.push({
       path: `/view/monitor/deviceTotalProject`,
       query: {
        projId: row.projId,
        deviceType: row.deviceType,
    }
     });
    },
    deleteItem() {},
    colseDialog() {
      this.showDialog = false;
    },
    doneDialog() {
      this.showDialog = false;
      this.getList();
    },
    doneDialog2() {
      this.showDialog2 = false;
      this.getList();
    },
    colseDialog2() {
      this.showDialog2 = false;
    },
    handleCurrentChanges(val) {
      console.log("val11", val);

      // this.currentRow = val;
      this.item = val;
    },
  },
};
</script>

<style scoped lang="less">
.flex {
  display: flex;
  height: 100%;

  // justify-content: space-between;
}
.tree {
  width: 20%;
  height: 96%;
}
.form {
  width: 78%;
  margin-left: 2%;
}
.formItem {
  margin-bottom: 0px;
}
.btn {
  background-color: #9054e8;
  color: #ffffff;
  // color: #008f4d;
}
.btn:hover {
  background-color: #7714a6;
}
.btns {
  background-color: #605ad9;
  color: #ffffff;
  // color: #008f4d;
}
.btns:hover {
  background-color: #ea6d12;
}
.detail {
  color: #008f4d;
}
</style>
