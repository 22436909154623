<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    @close="colseDialog"
    @submit="submit"
    width="30%"
  >
    <div class="editTitle">设备基本信息</div>

    <div class="edit-main">
      <el-form
        :model="editForm"
        label-width="120px"
        :rules="rules"
        ref="editVersionForm"
      >
        <el-row >
          <el-col :span="24">
            <el-form-item label="所属项目：" prop="projId">
                <el-select
                  class="block-select"
                  size="small"
                  clearable
                  filterable
                  @change="changeProject"
                  v-model="editForm.projId"
                  placeholder="请选择所属项目"
                >
                  <el-option
                    v-for="item in projectList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备类型：" prop="equipType">
                <el-select
                  class="block-select"
                  size="small"
                  clearable
                  v-model="editForm.equipType"
                  @change="changeEquipType"
                  placeholder="请选择设备类型"
                >
                  <el-option
                    v-for="item in equipType"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="现场编号：" prop="actNo">
              <!-- <el-input
                v-model="editForm.actNo"
                size="small"
                placeholder="请输入现场编号"
              ></el-input> -->
              <el-select
                  class="block-select"
                  size="small"
                  clearable
                  filterable 
                  v-model="editForm.actNo"
                  @change="actNoChange"
                  placeholder="请选择现场编号："
                >
                  <el-option
                    v-for="item in actNoList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备编号：" >
              <el-input
                v-model="editForm.equipNo"
                readonly
                size="small"
                placeholder="请输入设备编号"
              ></el-input>
       
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="监控IMEI：" prop="deviceId">
              <el-select
                  class="block-select"
                  filterable 
                  size="small"
                  clearable
                  v-model="editForm.deviceId"
                  placeholder="请选择监控IMEI"
                >
                  <el-option
                    v-for="item in terminalgetList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
import basic from '../../api/modules/basic'
  import {equipType} from "../../config/dataStatus"
//   import versionMange from "../../../api/modules/versionMange";

export default {
  name: "bindingMonitor",
  components: { CustomDialog },
  props: ["visible", "title"],
  data() {
    return {
      editForm: {
        equipNo:'',
        projId:'',
        actNo:'',
        deviceNo:''
      },
      rules: {
        actNo: [{ required: true, message: "请输入现场编号", trigger: "blur" }],
        projId: [
          { required: true, message: "请选择所属项目", trigger: "change" },
        ],
        equipType: [{ required: true, message: "请选择设备类型", trigger: "blur" }],
      
        deviceId: [
          { required: true, message: "请输入监控IMEI", trigger: "blur" },
        ],
      },
      uploadUrl: config.uploadUrl,
    
      projectList:[],
      terminalgetList:[],
      actNoList:[],
      equipNoList:[],
      equipType:[]
     
    };
  },
  mounted(){
    this.getProjectList()
    this.getterminalGetList()
   
  },
  methods: {
    colseDialog() {
      this.$emit("close");
    },
    changeProject(){

      this.equipType=equipType
      this.editForm.equipType=''
    },
    changeEquipType(val){
      this.editForm.actNo=''
      this.editForm.equipNo=''
      this.getBaseEquipList(this.editForm.projId,val)
    },
    getBaseEquipList(projId,equipType){
      this.actNoList=[]
      let params = {
        equipType:equipType,
        projId:projId
      }
      basic.getBaseEquipListAll(params).then(res=>{
       
        res.data.map(item=>{
          this.actNoList.push({
            label:item.actNo,
            value:item.actNo
          })
        
        })
      })
    },
    actNoChange(val){
      let params = {
        equipType:this.editForm.equipType,
        projId:this.editForm.projId
      }
      // this.editForm.equipNo=''
      basic.getBaseEquipListAll(params).then(res=>{
    
        res.data.map(item=>{
          if(item.actNo == val){ 
            this.editForm.equipNo = item.equipNo
         
          }
        })
      })
    },
    getterminalGetList(){
      basic.getBingList().then(res=>{
     
        res.data.map(item=>{
          this.terminalgetList.push({
            label:item.deviceImei,
            value:item.deviceId
          })
        })
        
      })
    },
    getProjectList(){
      basic.GetProjectList().then(res=>{
       
        res.data.map(item=>{
          this.projectList.push({
            label:item.projName,
            value:item.projId
          })
        })
        
      })
    },
    resetForm(formName) {
        this.$refs[formName].resetFields();
      },
    submit() {
      this.$refs.editVersionForm.validate((valid) => {
        if (valid) {
          basic.binding(this.editForm).then((res) => {
            this.$message({
              message: "新增成功",
              type: "success",
            });
            this.$emit("done");
            this.resetForm('editVersionForm')
          });
        }
      });
    },
   
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
</style>
